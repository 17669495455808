body.home .footer {
    margin-top: 100px;
}

.footer {
    background-color: $color-dark;
    padding: 100px 0 80px;

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 77.5px;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__mairie {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__title {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-size: $font-size--4;
        line-height: 1.25em;
        font-weight: 700;
        color: $color-white;
        margin: 0;

        svg {
            width: 15px;
            height: 15px;
            fill: $color-white;
        }
    }

    &__text {
        font-size: $font-size--text;
        line-height: 1.5em;
        font-weight: 400;
        color: $color-white;
        margin-top: 15px;
    }

    &__tel {
        display: block;
        font-weight: 700;
        margin-top: 10px;

        &:hover,
        &:focus {
            color: $color-main;
        }
    }

    &__logo {
        width: 165px;
        height: 90px;

        a {
            display: flex;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    &__button {
        width: 270px;
    }

    &-menu {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__item {
            position: relative;
            display: flex;
            align-items: center;
            column-gap: 25px;

            &::after {
                content: url($urlShort + 'home-icons/ic_plus_menu.svg');
            }

            &:last-child::after {
                display: none;
            }
        }

        &__link {
            font-size: $font-size--text-small;
            line-height: 1.45em;
            font-weight: 400;
            color: $color-white;
            padding: 5px 0;

            &:hover,
            &:focus {
                color: $color-main;
            }
        }
    }
}





//======================================================================================================
// RESPONSIVE 
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .footer {        
            &-menu {
                width: 75%;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 5px 35px;
        
                &__item {        
                    column-gap: 35px;
                }
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        body.home .footer {
            margin-top: 80px;
        }
        
        .footer {
            padding: 80px 0 60px;

            &__container {
                row-gap: 55px;
            }
        
            &__content {
                flex-wrap: wrap;
                gap: 60px 30px;
            }
        
            &__title {
                column-gap: 10px;
                font-size: toRem(22);
                line-height: 1.4em;
            }

            &__tel {
                margin-top: 15px;
            }
        
            &__button {
                width: 270px;
            }
        
            &__logo {
                width: 100%;
                height: auto;

                a {
                    width: 110px;
                    height: 60px;
                }
            }
        
            &-menu {
                width: 100%;
            }
        }
    }