.search-container {
    display: flex;
    position: relative;
    align-items: center;

    #searchInput {
        height: $search-input-height;
        padding: 24px 70px 24px 24px;
        background-color: $search-input-bg;
        border-color: $search-input-border-color;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }

        &:focus {
            border-color: $search-input-border-color--active;
        }
    }

    .search-svg {
        @include flex($alignItems: center, $justifyContent: center);
        position: absolute;
        top: 0;
        right: 0;
        @include size($search-input-button-width, $search-input-button-height);
        background-color: $search-input-button-bg;
        border: $search-input-button-border;
        cursor: pointer;

        svg {
            width: 15px;
            height: 15px;
            fill: $search-input-button-color;
            transition: all $duration;
        }

        &:hover, &:focus {
            background-color: $search-input-button-bg--active;
            border-color: $search-input-button-border-color--focus;

            svg {
                fill: $search-input-button-color--active;
            }
        }
    }
}

.searchResult {
    position: relative;
    display: flex;
    flex-direction: column;
    background: $color-white;
    border: 1px solid $color-dark;
    border-top: none;


    li {
        position: relative;
        background: $color-white;
        border-bottom: 1px solid $color-dark;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px 24px;
            transition: all $duration;
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            font-weight: 700;
            color: $color-text;
            transform: translate(0,-50%);
            transition: all $duration;
        }

        &.no-results {
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 15px 24px;
                color: $color-text;
            }
        }

        &:not(.no-results) {
            a:hover {
                padding-left: 35px;
                background: $color-dark;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }

            &.selected,
            &:hover {
                .resultCPT {
                    color: $color-white;
                }
            }
        }

        &:last-child {
            border-bottom:0;
        }

        &.selected {
            a {
                padding-left: 35px;
                background: $color-dark;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 18px/1 dashicons;
                color: $color-text;
                transition: all $duration;
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 30px;
    }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: $color-dark;
    transition: $duration;

    &::after {
        content: url($urlShort + 'home-icons/forme_recherche.svg');
        width: 1125px;
        position: absolute;
        right: -30px;
        bottom: -20px;
        z-index: -1;
        opacity: .3;
    }

    &__container {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
        overflow-y: auto;
    }

    .container {
        position: relative;
        height: 100%;
        

        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;   
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 20px auto;
            padding-bottom: 0;
            border: 0;
            font-family: $font-family--heading;
            font-size: $font-size--1;
            font-weight: $font-weight--heading;
            color: $color-white;
            text-transform: none;
        }

        .search-container {
            display: flex;
            position: relative;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;

            #searchInput {
                border-color: $color-white;

                &:focus {
                    border-color: $color-white;
                }
            }
        }

        .searchResult {
            width: calc(100% - 200px);
            left: 100px;
            border: none;
            border-top: 1px solid $color-dark;

            li {        
                &:not(.no-results) {
                    a:hover {
                        background: $color-main;
                        color: $color-text;
        
                        &::after {
                            color: $color-text;
                        }
                    }

                    &.selected,
                    &:hover {
                        .resultCPT {
                            color: $color-text;
                        }
                    }
                }
        
                &:last-child {
                    border-bottom:0;
                }
        
                &.selected {
                    a {
                        background: $color-main;
                        color: $color-text;
        
                        &::after {
                            color: $color-text;
                        }
                    }
                }
            }
        }
    }

    .search-close {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 0;
        width: 50px;
        height: 50px;
        padding: 0;
        background: $color-bg--transparent;
        border-color: $color-main;
        border-radius: 0;
        transition: $duration;
        
        svg {
            width: 25px;
            height: 25px;
            fill: $color-white;
            transition: all $duration;
        }

        &:hover,
        &:focus {
            background: $color-main;

            svg {
                fill: $color-text;
            }
        }
    }
}


// //======================================================================================================
// // Search Page
// //======================================================================================================
.filter {
    .search-container {
        .searchInput:focus {
            border-color: $search-input-border-color--active !important;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    .search-popup .container .searchResult {
        left: 70px;
        width: calc(100% - 140px);
    }

    .search-popup::after {
        width: 900px;
    }
}


// 960
@media screen and (max-width: $medium) {
    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }

    .search-popup::after {
        width: 95%;
    }
}


// 640
@media screen and (max-width: $small) {

    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-popup .container label {
        @include font-size(40);
    }

}
