.cover {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin-bottom: 30px;

    &-image {
        width: 100%;

        &__container {
            width: 100%;
        }

        &__content {
            width: calc(100% + 100px);
            height: 525px;
            background-color: $color-bg--image;
            padding: 0;
            margin-left: -50px;

            &--nodefault {
                height: 105px;
                background-color: $color-bg--transparent;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &-content {
        width: 100%;

        &__container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__title {
        margin: 20px 0 0;
    }

    &__text {
        font-size: $font-size--5;
        line-height: 1.4em;
        font-weight: 600;
        margin-top: 15px;
    }
}





//======================================================================================================
// RESPONSIVE
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .cover {  
            row-gap: 30px;     

            &-image__content {
                width: calc(100% + 70px);
                height: 420px;
                margin-left: -35px;

                &--nodefault {
                    height: 65px;
                }
            }
        } 
    }



    // 960
    @media screen and (max-width: $medium) {
        .cover {        
            &-image {
                &__container {
                    max-width: 100%;
                    padding: 0;
                }

                &__content {
                    width: 100%;
                    height: 380px;
                    margin-left: 0;

                    &--nodefault {
                        height: 75px;
                    }
                }
            }

            &__title {
                column-gap: 12.5px;
                font-size: toRem(44);

                &::before {
                    width: 20px;
                    height: 8px;
                }
            }
        } 
    }



    // 640
    @media screen and (max-width: $small) {
        .cover {  
            row-gap: 20px;    
                    
            &__image {
                width: calc(100% + 70px);
                height: 420px;
                margin-left: -35px;
            }

            &__title {
                font-size: toRem(38);
            }

            &__content {

                &--nodefault {
                    height: 40px;
                }
            }
        } 
    }