//======================================================================================================
// GENERAL
//======================================================================================================
body.js-navsticky #content {
    margin-top: 105px;
}

.home {
    &-button {
        min-height: 50px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        background: $color-main;
        box-sizing: border-box;
        transition: $duration;

        &::before {
            content: '';
            width: 0;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background-color: $color-white;
            transition: $duration ease-in-out;
        }

        &:hover,
        &:focus {
            background-color: $color-main;

            &::before {
                width: 100%;
            }

            .home-button {
                &__icon {
                    fill: $color-text;
                    transform: translateX(5px);
                }

                &__text {
                    color: $color-text;
                }
            }
        }

        &__icon {
            width: 25px;
            height: 25px;
            position: relative;
            fill: $color-text;
            transition: $duration;
        }

        &__text {
            position: relative;
            font-size: $font-size--text-small;
            line-height: 1.45em;
            font-weight: 700;
            color: $color-text;
            text-transform: $btn-text-transform;
            transition: $duration;
        }

        &--border {
            background-color: transparent;
            border: 1px solid $color-main;

            .home-button {
                &__icon {
                    fill: $color-white;
                }
                
                &__text {
                    color: $color-white;
                }
            }

            &:hover,
            &:focus {
                background-color: transparent;
                border-color: $color-white;
            }
        }
    }

    &-title {
        display: flex;
        align-items: center;
        column-gap: 15px;
        font-size: $font-size--1;
        line-height: 1.1em;
        font-weight: 700;
        color: $color-text;
        text-transform: lowercase;
        margin: 0;

        &::before {
            content: '';
            width: 35px;
            height: 10px;
            background-color: $color-second;
        }
    }
}





//======================================================================================================
// AGENDA
//======================================================================================================
.ag {
    &__container {
        width: 100%;
    }

    &__content {
        width: calc(100% + 100px);
        position: relative;
        margin: 0 0 0 -50px !important;
    }

    &-item {
        height: 525px !important;
        position: relative;
        display: block;

        &:hover,
        &:focus {
            .ag-item {
                &__image {
                    img {
                        transform: scale(1.1);
                    }
                }

                &__category {
                    color: $color-main;

                    svg {
                        fill: $color-main;
                        transform: rotate(90deg);
                    }
                }

                &-date {
                    color: $color-white;
                }
            }
        }

        &__image {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;

            &::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.255208) 40.62%, rgba(0, 0, 0, 0) 100%);
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-events.png) $color-bg--image no-repeat center;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }
        }

        &__content {
            max-width: 340px;
            position: absolute;
            left: 50px;
            bottom: 50px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 20px;
        }

        &__category {
            display: flex;
            align-items: center;
            column-gap: 10px;
            font-size: $font-size--text-medium;
            line-height: 1.25em;
            font-weight: 400;
            color: $color-white;
            text-transform: uppercase;
            filter: drop-shadow(0px 0px 30px rgba($color-black, .4));
            transition: $duration;

            svg {
                width: 15px;
                height: 15px;
                fill: $color-white;
                transition: $duration;
            }
        }

        &-date {
            font-size: $font-size--2;
            line-height: 1.15em;
            font-weight: 700;
            color: $color-main;
            text-transform: lowercase;
            filter: drop-shadow(0px 0px 30px rgba($color-black, .4));
            transition: $duration;

            &__start,
            &__end {
                display: flex;
                align-items: center;
                column-gap: 4px;
            }
        }

        &__title {
            font-size: $font-size--3;
            line-height: 1.2em;
            font-weight: 700;
            color: $color-white;
            margin: 0;
            filter: drop-shadow(0px 0px 30px rgba($color-black, .4));
        }
    }

    &__test {
        position: absolute;
        right: 50px;
        bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        row-gap: 22.5px;
    }

    &__pagination {
        width: fit-content !important;
        display: flex;
        column-gap: 10px;
        
        .swiper-pagination-bullet {
            width: 10px;
            height: 3px;
            position: relative;
            background-color: $color-white;
            border: none;
            border-radius: 0;
            margin: 0 !important;
            opacity: 1;
            transition: $duration;

            &::before {
                content: url(/wp-content/themes/hermine/assets/src/images/sprite-images/home-icons/ic_plus_agenda.svg);
                position: absolute;
                width: 10px;
                height: 10px;
                top: -10px;
                left: 0;
                opacity: 0;
                transition: $duration;
            }

            &:hover,
            &:focus {
                background-color: $color-second;

                &::before {
                    opacity: 1;
                }
            }
        }

        .swiper-pagination-bullet-active {
            background-color: $color-second;

            &::before {
                opacity: 1;
            }
        }
    }

    &__button {
        width: 270px;

        &--mobile {
            display: none;
        }
    }
}





//======================================================================================================
// ACTUALITES
//======================================================================================================
.ac {
    position: relative;
    margin-top: 100px;

    &::after {
        content: '';
        width: 100%;
        height: 95%;
        position: absolute;
        left: 0;
        bottom: -165px;
        z-index: -1;
        background-color: $color-dark;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 35px;
    }

    &-item {
        width: 570px;
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        &:nth-child(2) {
            transform: translateY(-90px);
        }

        &:hover,
        &:focus {
            .ac-item__image img {
                transform: scale(1.1);
            }
        }

        &__image {
            width: 100%;
            height: 330px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 5px;
        }

        &__title {
            font-size: $font-size--4;
            line-height: 1.2em;
            font-weight: 700;
            color: $color-white;
            margin: 0;
            transition: $duration;
        }

        &__text {
            font-size: $font-size--text-small;
            line-height: 1.45em;
            font-weight: 400;
            color: $color-white;
        }
    }

    &__button {
        width: 270px;
        margin: 0 0 0 auto;
        transform: translateY(-100%);
    }
}





//======================================================================================================
// NEWSLETTER + PLAQUETTE
//======================================================================================================
.nlpq {
    margin-top: 70px;

    &__container {
        width: 100%;
        display: flex;
        column-gap: 30px;
    }

    &-item {
        width: 50%;
        height: 240px;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        padding: 30px 40px;
        overflow: hidden;

        &--newsletter {
            background-color: $color-second;

            &:hover,
            &:focus {
                .nlpq-item {
                    &__icon {
                        fill: $color-text;
                        transform: rotate(90deg);
                    }

                    &__background {
                        transform: scale(1.1);
                    }
                }
            }

            .nlpq-item {
                &__content {
                    max-width: 320px;
                }

                &__icon {
                    fill: $color-main;
                }

                &__background {
                    width: 230px;
                    height: 245px;
                    position: absolute;
                    top: 50px;
                    right: 75px;
                    fill: $color-main;
                    opacity: .4;
                    transition: $duration;
                }
            }
        }

        &--plaquette {
            &:hover,
            &:focus {
                .nlpq-item {
                    &__icon {
                        fill: $color-main;
                        transform: rotate(90deg);
                    }

                    &__background img {
                        transform: scale(1.1);
                    }
                }
            }

            .nlpq-item {
                &__content {
                    max-width: 240px;
                }

                &__icon {
                    fill: $color-text;
                }

                &__background {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: $duration;
                    }
                }
                
            }
        }

        &__content {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__icon {
            width: 40px;
            height: 40px;
            margin-bottom: 30px;
            transition: $duration;
        }

        &__title {
            font-size: $font-size--3;
            line-height: 1.15em;
            font-weight: 700;
            color: $color-text;
            text-transform: lowercase;
            margin: 0 0 10px;

            &::before {
                display: none;
            }
        }

        &__text {
            font-size: $font-size--text-small;
            line-height: 1.45em;
            font-weight: 400;
            color: $color-text;
        }
    }
}






//======================================================================================================
// SOCIAL WALL
//======================================================================================================
.sowl {
    margin-top: 100px;

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__title {
        position: relative;
        z-index: 11;
        margin: 40px 0 0 530px;
    }

    &__content {
        width: 100%;
        display: flex;
        align-items: flex-end;
        column-gap: 35px;
        margin-top: -95px;
    }

    &-socials {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        &__item {
            width: 40px; 
            height: 40px;
            background-color: $color-main;
            transition: $duration;

            &:hover,
            &:focus {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }

            svg {
                width: 100%;
                height: 100%;
                fill: $color-text;
                transition: $duration;
            }
        }
    }


    #ff-stream-1 {
        width: auto;
        min-height: auto !important;
        display: flex;
        background-color: transparent;
        padding: 0;
        margin: 0;
    
        .ff-stream-wrapper {
            width: 100%;
            height: 330px !important;
            display: flex;
            justify-content: space-between;
            padding: 0;
            margin: 0;
        }
    
        .ff-item {
            width: 100% !important;
            height: 100%;
            position: relative !important;
            transform: none !important;
            visibility: inherit !important;
    
            &:hover,
            &:focus {
                .ff-content {
                    background-color: $color-main;
                }

                .ff-img-holder {
                    img {
                        transform: scale(1.1) translate(-50%,-50%);
                    }
                }
            }
    
            .picture-item__inner {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                color: $color-white;
                background-color: transparent;
                box-shadow: none;

                &::before {
                    display: none;
                }
            }
    
            .ff-item-cont {
                height: 100%;
                display: flex;
                align-items: flex-end;
                column-gap: 30px;
            }
    
            .ff-img-holder {
                width: 500px;
                height: 100%; 
                background-color: transparent;  
                overflow: hidden; 
    
                img {
                    width: 100%;
                    height: 100%;
                    min-height: 100% !important;
                    object-fit: cover;
                    transition: $duration;
                }
            }
    
            .ff-content {
                width: 430px;
                height: 200px !important;
                font-size: $font-size--text-small;
                line-height: 1.4em;
                font-weight: $font-weight-normal;
                color: $color-text;
                background-color: $color-second;
                padding: 20px 30px 80px;
                margin: 0;
                transition: $duration;
    
                a {
                    color: $color-text;
                }

                br {
                    margin: 5px 0 0;
                }
            }
    
            .ff-item-meta,
            .ff-carousel-icon {
                display: none;
            }
    
            .ff-item-bar {
                width: 370px;
                height: 35px;
                position: absolute;
                right: 30px;
                bottom: 30px;
                z-index: 1;
                display: flex;
                align-items: flex-end;
                column-gap: 10px;
                padding: 0;
                border-top: 1px solid $color-white;
                transition: $duration;
    
                a {
                    color: $color-text !important;
                    margin-left: 0 !important;
                }
    
                .ff-share-wrapper {
                    top: 0;
                    margin: 0 0 0 auto;
    
                    .ff-icon-share:before {
                        color: $color-text;
                    }
    
                    .ff-share-popup {
                        right: 0;
                        bottom: 25px;
                        background-color: $color-text;
                        border-color: $color-text;
                        border-radius: 0;
    
                        &::before,
                        &::after {
                            display: none;
                        }
    
                        a {
                            color: $color-white !important;
                        }
                    }
                }
            }
        }
    
        .ff-loadmore-wrapper,
        .shuffle__sizer {
            display: none;
        }
    }
}




//======================================================================================================
// RESPONSIVE 
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        //======================================================================================================
        // AGENDA
        //======================================================================================================
        .ag {
            &__content {
                width: calc(100% + 70px);
                margin: 0 0 0 -35px !important;
            }

            &-item {
                height: 420px !important;

                &__content {
                    left: 35px;
                    bottom: 35px;
                }

                &__category {
                    font-size: $font-size--5;
                    line-height: 1.45em;
                }

                &-date {
                    font-size: $font-size--3;
                }

                &__title {
                    font-size: toRem(28);
                    line-height: 1.25em;
                }
            }

            &__test {
                right: 35px;
                bottom: 35px;
            }
        }





        //======================================================================================================
        // ACTUALITES
        //======================================================================================================
        .ac {        
            &::after {
                bottom: -145px;
            }
        
            &__content {
                margin-top: 30px;
            }
        
            &-item {
                width: 460px;
        
                &:nth-child(2) {
                    transform: translateY(-85px);
                }
        
                &__image {
                    height: 265px;
                }
        
                &__title {
                    font-size: toRem(22);
                    line-height: 1.4em;
                }
            }
        }





        //======================================================================================================
        // NEWSLETTER + PLAQUETTE
        //======================================================================================================
        .nlpq {
            margin-top: 75px;
        
            &__container {
                column-gap: 20px;
            }
        
            &-item {        
                &--newsletter {        
                    .nlpq-item__background {
                        right: -50px;
                    }
                }
        
                &__title {
                    font-size: toRem(28);
                    line-height: 1.25em;
                }
            }
        }






        //======================================================================================================
        // SOCIAL WALL
        //======================================================================================================
        .sowl {        
            &__title {
                font-size: toRem(45);
                margin: 0 0 0 420px;
            }
        
            &__content {
                column-gap: 25px;
                margin-top: -50px;
            }
        
        
            #ff-stream-1 {            
                .ff-stream-wrapper {
                    height: 285px !important;
                }
            
                .ff-item {            
                    .ff-img-holder {
                        width: 400px;
                    }

                    .ff-item-cont {
                        column-gap: 20px;
                    }
            
                    .ff-content {
                        width: 380px;
                        padding: 20px 20px 70px;
                    }
            
            
                    .ff-item-bar {
                        width: 340px;
                        right: 20px;
                        bottom: 20px;
                    }
                }
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        //======================================================================================================
        // GENERAL
        //======================================================================================================
        #content {
            margin-top: 0;
        }

        .home-title {
            font-size: $font-size--2;
            line-height: 1.15em;

            &::before {
                width: 20px;
                height: 8px;
            }
        }





        //======================================================================================================
        // AGENDA
        //======================================================================================================
        .ag {
            &__container {
                max-width: 100%;
                padding: 0;
            }

            &__content {
                width: 100%;
                margin: 0 !important;
            }

            &-item {
                height: 380px !important;

                &__content {
                    max-width: 300px;
                    left: calc(5% + 10px);
                    bottom: 45px;
                    row-gap: 12.5px;
                }

                &__category {
                    font-size: $font-size--text;
                    filter: drop-shadow(0px 0px 30px rgba($color-black, .4));
                }

                &-date {
                    font-size: toRem(28);
                    line-height: 1.1em;
                    filter: drop-shadow(0px 0px 30px rgba($color-black, .4));
                }

                &__title {
                    font-size: $font-size--4;
                    filter: drop-shadow(0px 0px 30px rgba($color-black, .4));
                }
            }

            &__test {
                right: calc(5% + 10px);
                bottom: 45px;
            }
        }





        //======================================================================================================
        // ACTUALITES
        //======================================================================================================
        .ac {    
            margin-top: 80px;   

            &::after {
                height: 95%;
                bottom: -110px;
            }
        
            &__content {
                column-gap: 20px;
                margin-top: 25px;
            }
        
            &-item {
                width: 50%;
        
                &:nth-child(2) {
                    transform: translateY(-65px);
                }
        
                &__image {
                    height: 200px;
                }
        
                &__title {
                    font-size: $font-size--text-medium;
                    line-height: 1.25em;
                }
            }
        }





        //======================================================================================================
        // NEWSLETTER + PLAQUETTE
        //======================================================================================================
        .nlpq {
            margin-top: 30px;
        
            &-item {    
                padding: 20px;   

                &--newsletter {        
                    .nlpq-item {
                        &__content {
                            max-width: 240px;
                        }

                        &__background {
                            width: 160px;
                            height: 170px;
                            top: 90px;
                            right: -10px;
                        }
                    }
                }

                &__icon {
                    width: 30px;
                    height: 30px;
                    margin-bottom: 20px;
                }
        
                &__title {
                    font-size: $font-size--4;
                    line-height: 1.2em;
                }
            }
        }






        //======================================================================================================
        // SOCIAL WALL
        //======================================================================================================
        .sowl {    
            margin-top: 80px;

            &__title {
                margin: 0 0 0 calc(50% - 20px);
            }
        
            &__content {
                column-gap: 20px;
                margin-top: -40px;
            }

            &__feed {
                flex: 1;
            }
        
        
            #ff-stream-1 {            
                .ff-stream-wrapper {
                    height: 225px !important;
                }
            
                .ff-item {            
                    .ff-img-holder {
                        width: 50%;
                    }
            
                    .ff-content {
                        width: 50%;
                        height: 160px !important;
                        padding: 15px 15px 65px;
                    }
            
            
                    .ff-item-bar {
                        width: calc(50% - 40px);
                        right: 15px;
                        bottom: 15px;
                    }
                }
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        //======================================================================================================
        // GENERAL
        //======================================================================================================
        .home-title {
            font-size: $font-size--3;
        }





        //======================================================================================================
        // AGENDA
        //======================================================================================================
        .ag {
            &__container {
                display: flex;
                flex-direction: column;
                row-gap: 20px;
            }

            &-item {
                &__content { 
                    row-gap: 12.5px;
                }
            }

            &__test {
                bottom: 20px;
            }

            &__button {
                display: none;
                margin: 0 calc(5% + 10px) 0 auto;

                &::before {
                    background-color: $color-dark;
                }

                &:hover,
                &:focus {
                    .home-button {
                        &__icon {
                            fill: $color-white;
                        }

                        &__text {
                            color: $color-white;
                        }
                    }
                }

                &--mobile {
                    display: flex;
                }
            }
        }





        //======================================================================================================
        // ACTUALITES
        //======================================================================================================
        .ac {  
            &::after {
                height: 105%;
                bottom: -195px;
            }
            
            &__content {
                flex-direction: column;
                row-gap: 20px;
            }
        
            &-item {
                width: 100%;
        
                &:nth-child(2) {
                    transform: unset;
                }
        
                &__image {
                    height: 175px;
                }
        
                &__title {
                    font-size: $font-size--text-medium;
                    line-height: 1.25em;
                }
            }

            &__button {
                margin-top: 40px;
                transform: unset;
            }
        }





        //======================================================================================================
        // NEWSLETTER + PLAQUETTE
        //======================================================================================================
        .nlpq {
            margin-top: 80px;

            &__container {
                flex-wrap: wrap;
                row-gap: 20px;
            }
        
            &-item {  
                width: 100%;    

                &--newsletter {        
                    .nlpq-item__background {
                        right: 30px;
                    }
                }
            }
        }






        //======================================================================================================
        // SOCIAL WALL
        //======================================================================================================
        .sowl {    
            margin-top: 80px;

            &__title {
                margin: 0;
            }
        
            &__content {
                flex-direction: column;
                align-items: flex-end;
                row-gap: 20px;
                margin-top: 20px;
            }   
            
            &__feed {
                width: 100%;
            }

            &-socials {
                flex-direction: row;
                column-gap: 10px;
            }
        
            #ff-stream-1 {   
                .ff-stream-wrapper {
                    height: auto !important;
                }     

                .ff-item { 
                    .ff-item-cont {
                        flex-direction: column-reverse;
                        row-gap: 20px;
                    }  

                    .ff-img-holder {
                        width: 100%;
                        height: 225px;
                    }
            
                    .ff-content {
                        width: 100%;
                    }
            
            
                    .ff-item-bar {
                        width: calc(100% - 30px);
                        bottom: calc(100% - 145px);
                    }
                }
            }
        }
    }