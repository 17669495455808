//======================================================================================================
// Bloc Nombres
//======================================================================================================
.bloc-numbers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px 0;

    &__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
    }

    &__figure {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--2;
        color: $color-main;
    }

    &__unit {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--3;
        color: $color-main;
    }

    &__exponent {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--text;
        color: $color-main;
    }

    &__caption {
        font-family: $font-family--heading;
        font-weight: $font-weight;
        color: $color-text;
        font-size: $font-size--5;
        max-width: 250px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .bloc-numbers {
        &__block {
            flex-basis: 50%;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    
    .bloc-numbers {
        &__block {
            flex-basis: 100%;
        }
    }

}
