.nav-main--burger {
    z-index: inherit;
    height: 15px;
    background-color: $color-main;

    // Burger menu Button
    .nav-main-buttons {
        position: relative;

        &__menu {
            position: absolute;
            bottom: 0;
            right: 15px;
            z-index: 11;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 15px;
            width: fit-content;
            margin: 0;
            padding: 30px;
            background-color: $color-main;
            border: 0;
            border-radius: 0;
            transition: all $duration ease-in-out;
            cursor: pointer;

            &::after {
                content: "Menu";
                font-size: $font-size--5;
                font-weight: $font-weight; 
                color: $color-white;
                text-transform: uppercase;
            }

            // Burger menu Button icon
            &__icon {
                position: relative;
                top: -1px;
                display: block;
                width: 20px;
                height: 2px;
                background-color: $color-white;
                transition: all $duration ease-in-out;

                &::before, 
                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: $color-white;
                    transition: all $duration ease-in-out;
                }

                &::before {
                    top: 7px;
                }

                &::after {
                    top: -7px;
                } 
            }
        }

        // For responsive
        &__submenu {
            display: none;
        }

    }

    // Popup menu
    &__popup {
        position: fixed;
        top: -100vh;
        left: 0;
        right: 0;
        z-index: 10;
        display: block;
        max-width: inherit;
        width: 100%;
        height: 100vh;
        background-color: $color-main;
        padding: 0;
        transition: all $duration ease-in-out;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 20px;
            left: 0;
            z-index: 1;
            height: 170px;
            background-color: $color-main;
        }
    }

    // Section menu (for overflow)
    &__section {
        overflow-y: auto;
        height: 100%;
        padding: 40px 0 20px 0;
    }

    // Container menu
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    // if Burger menu open
    &.js-active-menu {

        // Burger menu Button
        .nav-main-buttons__menu {   
            top: -70px;

            &::after {
                content: "Fermer";
            }

            // Burger menu Button icon
            &__icon {
                transform: rotateZ(-45deg);
                transition: all $duration ease-in-out;

                &::before, &::after {
                    width: 10px;
                    left: 5px;
                    transform: rotateZ(90deg);
                }
    
                &::before {
                    top: -6px;
                }
    
                &::after {
                    top: 6px;
                }
            }
        }

        // Popup menu
        .nav-main--burger__popup {
            top: 0;
        }

        // Main menu
        .menu {
            display: flex;
            transform: scaleY(1);
        }

    }

    // Main menu
    .menu {
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 5px;
        width: 40%;
        height: auto;
        margin-top: 130px;
        margin-bottom: 40px;
        padding: 0 50px 0 0;
        transition: all $duration ease-in-out;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background-color: $color-second;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);

            &.menu-item-has-children {
                .menu__link {
                    &::after {
                        content: '\f345';
                        position: inherit;
                        display: block;
                        width: auto;
                        height: auto;
                        background: $color-bg--transparent;
                        font: normal 1.250rem/1 dashicons;
                        color: rgba(255, 255, 255, 0.3);
                        transform: none;
                        transition: color $duration ease-in-out;
                    }

                    &:hover, &:focus {
                        &::after {
                            color: $color-white;
                        }
                    }
                }

                &:focus-within, 
                &:hover {
                    .submenu {
                        display: none;
                    }
                }

                &.js-active-submenu {
                    .submenu {
                        display: block;
                    }
                }
            }
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: inherit;
            width: 100%;
            height: auto;
            padding: 20px 0;
            color: $color-white;
            text-transform: uppercase;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: 1px;
                background-color: $color-white;
                transition: width $duration ease-in-out;
            }

            &::after {
                display: none;
            }

            &:hover, &:focus {
                background-color: $color-bg--transparent;
                color: $color-white;

                &::before {
                    width: 100%;
                    transition: width $duration ease-in-out;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        display: none;
        position: absolute;
        top: 0;
        right: inherit;
        left: 100%;
        z-index: inherit;
        width: 50vw;
        padding: 0 50px;
        background-color: $color-bg--transparent;
        box-shadow: none;

        &::after {
            content: "";
            position: absolute;
            left: -1px;
            top: 0;
            width: 1px;
            height: 100%;
            background-color: $color-second;
        }

        &__nav {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 5px 30px;
            max-width: inherit;
            max-height: 80vh;
            padding: 0;
        }

        &__item {
            width: auto;
            margin: 0;         
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: auto;
            height: auto;
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: 15px 0;
            border-bottom: 1px solid $color-second;
            color: $color-white;
            font-weight: $font-weight-medium;

            &::before {
                content: '';
                display: inherit;
                position: absolute;
                bottom: 0;
                width: 0;
                height: 1px;
                background: $color-text;
                transition: width $duration ease-in-out;
            }

            &::after {
                display: none;
            }

            &:hover, &:focus {
                &::before {
                    width: 100%;
                    transition: width $duration ease-in-out;
                }
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;

                &::before {
                    display: none;
                }
            }
        }
    }

}

// Button accessibility
button.avoid-main-content {
    position: absolute;
    bottom: 40px;
    right: 40px;
    pointer-events: none;

    &:focus {
        color: $color-white;
        border-color: $color-white;
    }
}

// For Responsive (no scroll on body when menu is open)
body.js-active-navmainburger {
    overflow: hidden;
}


//=================================================================================================
// Header only for burger menu on demosaisie
//=================================================================================================
// Tools
.header .tools--burger {
    top: auto;
}

// Identity
.identity.identity--burger {
    max-width: 85%;
    width: fit-content;
    min-height: 90px;
    height: auto;
    position: relative;
    z-index: 11;
}

body.js-active-navmainburger {
    .identity__title {
        margin-top: 30px;
        color: $color-white;
        transition: margin-top $duration ease-in-out, color $duration ease-in-out;
    }

    .quick-access {
        right: -960px;
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main--burger {

        // Main menu
        .menu {
            width: 30%;
            margin-top: 150px;
            padding: 0 30px 0 0;
        }

        // Sub menu
        .submenu {
            width: 65vw;
            padding: 0 30px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {
    
    // Header -- Identity
    .identity.identity--burger {
        position: inherit;
        z-index: inherit;
        max-width: 100%;
        height: auto;
    }
    body.js-active-navmainburger .identity__title {
        margin-top: 0;
        color: $color-text;
    }

    // Menu
    .nav-main--burger {
        position: initial;
        height: auto;

        // if Burger menu open
        &.js-active-menu {

            // Burger menu Button
            .nav-main-buttons__menu { 
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 45px;
                min-height: inherit;
                height: 45px;
                margin: 40px 40px 0 auto;
                padding: 0;
                border-radius: $border-radius--round;
                border: 2px solid $color-white;
        
                &::after {
                    display: none;
                }
            }

            // Popup menu
            .nav-main--burger__popup {
                left: 0;
            }

            // And If submenu open
            .nav-main-buttons.js-active-submenu {
                .nav-main-buttons__submenu {
                    left: 0;
                }
            }
        }

        // If submenu open
        &.js-active-submenu {
            .nav-main--burger__section {
                overflow: hidden;
            }
        }

        // Burger Buttons
        .nav-main-buttons {
            position: inherit;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 69px;

            // Burger menu Button
            &__menu {
                position: relative;
                width: fit-content;
                top: auto;
                right: auto;
                padding: 20px 0;
                transition: none;

                // Burger menu Button icon
                &__icon, 
                &__icon::before, 
                &__icon::after {
                    transition: none;
                }   
            }

            // Burger menu responsive - Close submenu
            &__submenu {
                position: absolute;
                top: 0;
                left: -100vw;
                z-index: 11;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 45px;
                height: 45px;
                margin: 40px auto 0 40px;
                padding: 0;
                background-color: $color-bg--transparent;
                border: 2px solid $color-white;
                border-radius: $border-radius--round;
                transition: all $duration ease-in-out;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        stroke: $color-dark;
                    }
                }

                svg {
                    width: 40px;
                    height: 40px;
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2px;
                }
            }
        }

        // Popup menu
        &__popup {
            top: 0;
            right: inherit;
            left: -100vw;
            padding: 0;

            &::before {
                height: 100px;
            }
        }

        // Section menu
        &__section {
            padding: 0;
        }

        // Container menu
        &__container {
            max-width: initial;
            margin: 0;
            padding: 0;
        }

        // Main menu
        .menu {
            flex-wrap: nowrap;
            width: 100%;
            height: auto;
            min-height: 100vh;
            margin: 0;
            padding: 100px 40px 40px;
            gap: 0;
            overflow: hidden;

            &::after {
                display: none;
            }

            &__item {
                &.menu-item-has-children {

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        color: $color-white;
                        transition: all $duration;
                    }

                    &:focus-within .submenu,
                    &:hover .submenu {
                        display: flex;
                    }

                    &.js-active-submenu {
                        .submenu {   
                            left: 0;
                            display: flex;
                            transition: all $duration ease-in-out;
                        }
                    }

                    .menu__link::after {
                        display: none;
                    }
                }
            }

            &__link {
                &::before {
                    display: none;
                }
            }
        }

        // Sub menu
        .submenu {
            top: 0;
            right: inherit;
            left: -100vw;
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            width: 100vw;
            height: 100vh;
            padding: 60px 0 0 0;
            background-color: $color-main;
            transition: all $duration ease-in-out;

            &::after {
                display: none;
            }
    
            &__nav {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex-wrap: nowrap;
                gap: initial;
                max-width: 100%;
                width: 100%;
                max-height: inherit;
                height: calc(100vh - 100px);
                overflow-y: auto;
                padding: 40px;  
            }

            &__item {
                width: 100%; 
            }

            &__link {
                width: 100%;

                &::before {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main--burger {
            .menu {
                padding-top: 100px;
            }

            .submenu {
                padding-top: 30px;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    // Admin 
    .admin-bar {
        .nav-main--burger {
            .submenu {
                padding-top: 45px;
            }
        }
    }

}
