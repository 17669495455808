.breadcrumb {
    ul  {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 5px;
    }

    li {
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 5px;

        &::after {
            content: url($urlShort + 'home-icons/ic_chevron_ariane.svg');
            width: 20px;
            height: 20px;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

        a,
        span {
            display: block;
            padding: 2.5px 0;
        }

        a {
            transition: $duration;

            &:hover,
            &:focus {
                color: $color-main;
            }
        }
    }
}