.nav-main {
    .menu {
        display: flex;
        column-gap: 72.5px;

        &__item {
            height: 105px;
            position: relative;

            &:last-child .menu__link::after {
                display: none;
            }

            &:hover, 
            &:focus-within {
                .menu__link {
                    color: $color-main;
                }

                .submenu {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;
                }
            }
        }   
        
        &__link { 
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            font-size: $font-size--5;
            line-height: 1.4em;
            font-weight: 500;
            color: $color-text;
            text-transform: uppercase;
            transition: $duration;

            &:after {
                content: url($urlShort + 'home-icons/ic_plus_menu.svg');
                position: absolute;
                top: 49%;
                right: -42.5px;
                transform: translateY(-50%);
            }
        }
    }



    .submenu {
        width: 235px;
        position: absolute;
        top: calc(100% - 17.5px);
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        background: $color-second;
        padding: 20px;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: $duration;

        &::before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            border-left: 12.5px solid transparent;
            border-right: 12.5px solid transparent;
            border-bottom: 12.5px solid $color-second;
        }

        &__nav {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &__item {
            width: 100%;
            display: flex;
        }

        &__link {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            font-size: $font-size--text-small;
            line-height: 1.45em;
            font-weight: $font-weight-medium;
            color: $color-text;
            border-bottom: 1px solid $color-white;
            padding: 5px 0 5px 17.5px;
            transition: $duration;

            &::before {
                content: '\f345';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                font: normal 14px/1 dashicons;
                color: $color-white;
                transition: $duration;
            }

            &:hover,
            &:focus {
                border-bottom-color: $color-text;

                &::before {
                    color: $color-text;
                }
            }
        }
    }
    


    &-button,  
    &__close,
    .submenu__close {
        display: none;
    }

}


//=================================================================================================
// Responsive
//=================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .nav-main {
            .menu {
                column-gap: 37.5px; 
                
                &__link { 
                    font-size: $font-size--text;
                    line-height: 1.5em;

                    &:after {
                        right: -22.5px;
                    }
                }
            }   
        }
    }


    // 960
    @media screen and (max-width: $medium) {
        body.js-active-navmain {
            overflow: hidden;
        }    


        .nav-main {
            &.js-active-menu {
                .nav-main__container {
                    left: 0;
                }
            }

            &-button {
                display: flex;
                align-items: center;
                column-gap: 10px;
                font-size: $font-size--5;
                font-weight: 500; 
                color: $color-text;
                text-transform: uppercase;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                padding: 0;
                margin: 0;
                cursor: pointer;
                transition: $duration;

                &:hover, 
                &:focus {
                    color: $color-main;
                    background-color: transparent;

                    .nav-main__icon {
                        background-color: $color-main;

                        &::before,
                        &::after {
                            background-color: $color-main;
                        }
                    }
                }
            }

            &__icon {
                width: 15px;
                height: 2px;
                position: relative;
                top: -1px;
                display: block;
                background: $color-main;
                transition: $duration;

                &:before, 
                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    display: block;
                    background: $color-main;
                    transition: $duration;
                }

                &:before {
                    top: 6px;
                }

                &:after {
                    top: -6px;
                }
            }

            &__container {
                width: 100%;
                height: 100vh;
                position: fixed;
                top: 0;
                left: -100vw;
                right: 100%;
                z-index: 990;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                row-gap: 30px;
                background-color: $color-dark;
                padding: 40px 0;
                transition: $duration;
            }

            &__close {
                width: 40px;
                height: 40px;
                position: relative;
                z-index: 10;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                border: 1px solid $color-main;
                margin: 0 30px 0 auto;
                padding: 0;
                transition: $duration;

                &:hover,
                &:focus {
                    background-color: $color-main;
                    border-color: $color-main;

                    svg {
                        fill: $color-text;
                    }
                }

                svg {
                    width: 24px;
                    height: 24px;
                    fill: $color-white;
                    transition: $duration;
                }
            }

            .menu {
                width: 100%;
                flex-direction: column;
                padding: 0 30px;

                &__item {
                    height: auto;
                    position: unset;

                    &:last-child .menu__link::after {
                        display: block;
                    }

                    &.menu-item-has-children.js-active-submenu {
                        .submenu {
                            right: 0;
                            justify-content: flex-start;
                        }
                    }
                }

                &__link {
                    width: 100%;
                    height: auto;
                    font-size: $font-size--5;
                    color: $color-white;
                    border-bottom: 1px solid $color-white;
                    padding: 15px 30px 15px 0;

                    &::after {
                        right: 0;
                    }
                }
            }

            .submenu {
                width: 100vw;
                height: 100vh;
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                top: 0;
                left: unset;
                right: 100%;
                transform: unset;
                z-index: 2;
                background-color: $color-dark;
                padding: 40px 0 60px 0;
                opacity: 1;
                pointer-events: inherit;
                visibility: visible;
                transition: $duration;

                &::before {
                    display: none;
                }

                &__close {
                    width: 40px;
                    height: 40px;
                    flex-shrink: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparent;
                    border: 1px solid $color-main;
                    padding: 0;
                    margin: 0 0 0 30px;
                    transform: rotate(90deg);
                    transition: $duration;

                    &:hover,
                    &:focus {
                        background-color: $color-main;
                        border-color: $color-main;

                        svg {
                            fill: $color-text;
                        }
                    }

                    svg {
                        width: 26px;
                        height: 26px;
                        fill: $color-white;
                        transform: rotate(-90deg);
                        transition: $duration;
                    }
                }

                &__nav {
                    max-width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding: 1px 30px;
                    overflow-y: auto;
                }

                &__link {
                    font-size: $font-size--text;
                    color: $color-white;
                    padding: 12.5px 0 12.5px 22.5px;

                    &::before {
                        font-size: $font-size--text;
                    }

                    &:hover,
                    &:focus {
                        color: $color-second;
                        border-bottom-color: $color-second;

                        &::before {
                            color: $color-second;
                        }
                    }
                }
            }
        }

        // Admin 
        .admin-bar .nav-main {
            &__container, 
            .submenu {
                padding-top: 70px;
            }
        }
    }