body {
    &.admin-bar {
        &.js-navsticky {
            .header {
                top: 32px;
            }
    
            .tools {
                top: 97px;
            }
        }

        .tools {
            top: 137px;
        }
    }
}

.header {
    width: 100%;
    height: 105px;
    position: relative;
    z-index: 100;
    background-color: $color-bg--body;

    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__content {
        height: 100%;
    }

    &--sticky {
        height: 65px;
        position: fixed;
        top: 0;
        left: 0;
        background-color: $color-dark;
        box-shadow: 0px 0px 15px 0px rgba($color-dark, .08);

        .main-logo {
            width: auto;
            height: 100%;
            background-color: transparent;
            box-shadow: none;

            svg {
                width: 155px;
                height: 25px;
                display: none;

                &.sticky {
                    display: block;
                }
            }
        }


        .nav-main {
            .menu {
                &__item {
                    height: 65px;
                }

                &__link {
                    color: $color-white;
                    transition: color 0s;

                    &:hover,
                    &:focus {
                        transition: $duration;
                    }
                }
            }

            .submenu {
                top: 100%;
            }
        }
    
        .tools {
            top: 65px;
        }
    }
}



//======================================================================================================
// Logo
//======================================================================================================
.main-logo {
    width: 280px;
    height: 210px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-dark;
    box-shadow: 0px 1px 15px 0px rgba($color-black, .25);
    // transition: all $duration, background-color 0s, box-shadow 0s;
    transition: 0s;

    svg {
        width: 220px;  
        height: 150px;
        fill: $color-white;
        transition: $duration;

        &.sticky {
            display: none;
        }
    }

    &__title {
        display: none;
    }
}



//======================================================================================================
// Tools
//======================================================================================================
.tools {
    width: 170px;
    height: 105px;
    position: fixed;
    top: 105px;
    right: -120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
    background-color: $color-main;
    transition: $duration;

    &:hover,
    &:focus-within {
        right: 0;
        background-color: $color-second;

        .tools__item span {
            opacity: 1;
            visibility: visible;
        }
    }

    #accessconfig {
        width: 100%;
        height: 30px;
    }

    &__item {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 10px;
        background-color: transparent;
        border: none;
        padding: 0 10px;
        margin: 0;
        transition: $duration;

        &:hover,
        &:focus {
            background-color: transparent;

            span::after {
                width: 100%;
                opacity: 1;
            }

            svg {
                fill: $color-text;
            }
        }

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        svg {
            width: 30px;
            height: 30px;
            fill: $color-text;
            transition: $duration;
        }

        span {
            position: relative;
            font-size: $font-size--text-small;
            line-height: 1.45em;
            font-weight: 700;
            color: $color-text;
            text-transform: uppercase;
            opacity: 0;
            visibility: hidden;
            transition: $duration;

            &::after {
                content: '';
                width: 0;
                height: 1px;
                position: absolute;
                right: 0;
                bottom: -2.5px;
                background-color: $color-text;
                opacity: 0;
                transition: $duration;
            }
        }
    }
}





//======================================================================================================
// RESPONSIVE
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {           
        .main-logo {
            width: 225px;
            height: 170px;
        
            svg {
                width: 175px;  
                height: 120px;
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) { 
        body.admin-bar {
            .header {
                top: unset;
            }
        }

        body.admin-bar {        
            .tools {
                top: unset;
            }
        }
        
        .header {
            height: 95px;
            position: relative;
            top: unset;
            left: unset;

            &__content {
                display: flex;
                align-items: center;
                column-gap: 30px;
            }
        }
        
        
        
        .tools {
            width: 110px;
            height: 45px;
            position: relative;
            top: unset;
            right: unset;
            flex-direction: row;
            column-gap: 10px;

            &:hover,
            &:focus-within {
                background-color: $color-main;
            }
        
            #accessconfig {
                width: 30px;
            }
        
            &__item {
                width: 30px;
                padding: 0;

                &:hover,
                &:focus {
                    svg {
                        fill: $color-white;
                    }
                }
        
                span {
                    display: none;
                }
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .header {
            height: 90px;

            &__content {
                flex-direction: column-reverse;
                justify-content: flex-end;
                row-gap: 10px;
            }
        }



        .main-logo {
            width: 175px;
            height: 130px;
        
            svg {
                width: 135px;  
                height: 92.5px;
            }
        }


        
        .tools {
            width: 100px;
        
            #accessconfig {
                width: 25px;
            }
        
            &__item {
                width: 25px;

                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }